import logo from './logo.svg';
import './App.css';
import { useOktoview } from 'oktoview-viewkit-react';
import { useEffect, useState } from 'react';

function App() {
	const { getProducts } = useOktoview();
	const [headerInfo, setHeaderInfo] = useState({});

	useEffect(() => {
		getProducts();
		// get current request headers information and  the headerInfo state var
		var req = new XMLHttpRequest();
		req.open('GET', document.location, true);
		req.send(null);
		req.onload = function () {
			var headers = req.getAllResponseHeaders();
			// turn headers into an object
			var arr = headers.trim().split(/[\r\n]+/);
			var headerInfo = {};
			arr.forEach(function (line) {
				var parts = line.split(': ');
				var header = parts.shift();
				var value = parts.join(': ');
				headerInfo[header] = value;
			});
			setHeaderInfo(headerInfo);
		};
	}, [getProducts]);

	// get current request headers information and  the headerInfo state var

	return (
		<div className='App'>
			{Object.keys(headerInfo).map(line => (
				<div>
					{line}: {headerInfo[line]}
				</div>
			))}
		</div>
	);
}

export default App;
